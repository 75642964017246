body {
    font-family: 'Inter', sans-serif;
    background-color: #f5f6f8;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    margin: 0;
    padding: 20px;
  }
  
  .container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 800px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 50px; 
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .header h1 {
    font-size: 28px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .header p {
    font-size: 16px;
    color: #666;
    margin: 0;
    line-height: 1.5;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  button {
    width: 100%;
    padding: 12px;
    margin-bottom: 10px;
    background-color: #3a7afe;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s ease;
  }
  
  button:disabled {
    background-color: #b0c4f6;
    cursor: not-allowed;
  }
  
  button:not(:disabled):hover {
    background-color: #345fde;
  }
  
  .generate-button {
    width: 100%;
    padding: 16px;
    background-color: #28a745; /* Green background */
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-top: 20px; /* Add margin to space it from the grid */
  }
  .announcement-bar {
    position: fixed;
    top: 0;
    left: 0; /* Ensure it starts from the left of the screen */
    width: 100%;
    background-color: #FFD700; /* Bright gold color */
    color: #000; /* Black text */
    padding: 10px 20px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    z-index: 1000; /* Ensure it stays on top */
  }
  

  
  
  .generate-button:hover {
    background-color: #218838;
  }
.progress-container {
    margin: 20px 0; /* Add some margin for spacing */
    text-align: center; /* Center the text */
    width: 100%; /* Full width of the parent container */
}

.progress-bar {
    width: 100%; /* Full width of the progress container */
    height: 20px; /* Height of the progress bar */
    border-radius: 10px; /* Rounded corners */
    appearance: none; /* Remove default styling */
    background-color: #f0f0f0; /* Background color for the bar */
}

/* Webkit and Firefox styles remain unchanged */
.progress-bar::-webkit-progress-bar {
    background-color: #f0f0f0; /* Background color for the webkit progress bar */
    border-radius: 10px; /* Rounded corners */
}

.progress-bar::-webkit-progress-value {
    background-color: #0070f3; /* Color of the progress value */
    border-radius: 10px; /* Rounded corners */
}

.progress-bar::-moz-progress-bar {
    background-color: #0070f3; /* Color of the progress value for Firefox */
    border-radius: 10px; /* Rounded corners */
}

.progress-container p {
    margin-top: 5px; /* Space between progress bar and text */
    font-size: 14px; /* Font size for the percentage text */
    color: #333; /* Color of the percentage text */
}


  .barcode-grid-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .barcode-container {
    max-height: 500px;
    overflow-y: auto;
    width: 100%;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Grid view */
    gap: 15px;
    box-sizing: border-box;
    background-color: #f9f9f9;
  }
  
  .barcode-container > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  .product-name {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    white-space: normal; /* Allows text to wrap */
    word-wrap: break-word; /* Break long words if necessary */
  }

  .barcode {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px; /* Increased width to better fit the barcode */
    text-align: center;
    margin-bottom: 20px;
  }
  
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .barcode svg {
    max-width: 100%; /* Ensures the barcode fits within the container */
    height: auto;
  }
  .header-section {
    background-color: #fff; /* Light grey background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    text-align: center;
  }
  
  .app-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333; /* Darker text color */
  }
  
  .app-description {
    font-size: 1.2rem;
    color: #555; /* Slightly lighter text color */
    line-height: 1.5;
    max-width: 800px;
    margin: 0 auto; /* Center the text */
  }
  .logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .logo {
    max-width: 150px; /* Adjust the width according to your design */
    height: auto;
  }

  @keyframes wiggle {
    0%, 100% {
      transform: rotate(-3deg);
    }
    50% {
      transform: rotate(3deg);
    }
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .wiggle-pulsate {
    animation: wiggle 0.3s ease-in-out infinite, pulsate 1.5s ease-in-out infinite;
  }