/* Basic Modal Styles */
.modal {
    display: none; /* Hidden by default */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal.show {
    display: flex; /* Show modal when active */
  }
  .original-price {
    text-decoration: line-through;
    color: #999;
    margin-right: 10px;
    font-size: 1.2rem;
  }
  /* Modal Content Styling */
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    position: relative;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  /* Close Button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  /* Pricing Options Container */
  .pricing-options {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  /* Pricing Card Styling */
  .pricing-card {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 200px;
    text-align: center;
    position: relative;
    transition: transform 0.3s;
  }
  
  .pricing-card:hover {
    transform: translateY(-5px);
  }
  
  /* Best Value Badge */
  .badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #ffcc00;
    color: #333;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
  }
  
  /* Highlight Best Value Card */
  .best-value {
    border: 2px solid #007bff;
  }
  
  /* Plan Title and Details */
  .plan-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .plan-price {
    font-size: 1.1rem;
    margin: 10px 0;
    color: green;
    font-weight: bold;
  }
  
  .plan-details {
    font-size: 0.9rem;
    margin-bottom: 20px;
  }
  
  /* Select Button */
  .select-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .select-button:hover {
    background-color: #0056b3;
  }
  